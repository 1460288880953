.socialMedia {
  justify-content: left !important;
  font-size: 30px;
}

#header {
  margin-top: 2% !important;
}

.list-group-item.list-group-item-info {
  color: black !important;
}

.uniTitle {
  font-size: 25px;
}

@media (max-width: 767px) {
  .image-info {
    margin-left: 18% !important;
  }
  .description-edit {
    width: 350px !important;
    margin-left: 5% !important;
  }
  a.nav-link {
    margin-left: 10px !important;
    font-weight: 700;
  }
  .mr-sm-2.navbar-nav {
    margin-right: 20px !important;
  }

  iframe {
    height: 200px !important;
    width: 250px !important;
  }
  #header {
    margin-top: 0px !important;
  }
  #headerImage {
    height: 300px !important;
    width: 300px !important;
    margin-top: 20px !important;
  }
  img {
    transform: rotate(360deg) !important;
  }
}

.description-edit {
  float: left;
  width: 500px;
  margin-left: 2%;
}

.image-info {
  float: left;
  margin-left: 5%;
  font-size: 14px;
  color: grey;
}

.content-description {
  width: 80%;
  margin: 0px auto;
}

img {
  cursor: pointer;
}

a.nav-link {
  color: #1e90ff !important;
}

a.nav-link {
  margin-left: 40px;
  font-weight: 700;
}

.mr-sm-2.navbar-nav {
  margin-right: 84px;
}

.descEditContent {
  font-weight: 600;
}

img#headerImageProf {
  margin-left: 25%;
  margin-bottom: 20px;
}

.no-border {
  border: none !important;
}

.imgEdit {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
}

.projContainer-expense:hover .overlay {
  opacity: 0.9;
  background-color: #008cba;
}

.projContainer-salon:hover .overlay {
  opacity: 0.9;
  background-color: green;
}

.projContainer-chat:hover .overlay {
  opacity: 0.9;
  background-color: yellowgreen;
}

.projContainer-shopping:hover .overlay {
  opacity: 0.9;
  background-color: #1e90ff;
}

.fa-github-square:hover {
  background-color: black;
}

.fa-linkedin:hover {
  background-color: #0077b5;
}

.btnEdit {
  color: black;
  font-size: 20px;
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.no-background {
  background: none !important;
}

body {
  background-color: rgb(250, 250, 251) !important;
}
